<template>
  <div>
    <style-guide-asset-card
      :src-asset="basketItem"
      :title="basketItem.name || '[ Missing Filename ]'"
    >
      <template #cardAdditionalContent>
        <div
          v-if="isBasketRemoveLoading || isOperationLoading"
          class="flex justify-center w-full h-4"
        >
          <div class="w-full text-xs flex justify-center mb-2">
            <base-svg class="h-3 w-3 mr-2 text-primary-red inline-block" src="icons/circleSpinner.svg" tag="div"/>
            <div class="text-primary-gray">
              {{ isBasketRemoveLoading ? 'Removing ...' : '' }}
              {{ isOperationLoading ? 'Please wait ...' : '' }}
            </div>
          </div>
        </div>

        <div
          v-else-if="canDownload"
          class="flex justify-center w-full"
        >
          <base-svg
            :title="'File Size: ' + printFileSize(basketItem.fileSize)"
            class="text-primary-red cursor-pointer hover:text-custom-gray-1 mr-3 w-4 h-4"
            :class="{ 'mr-3': basketItemType === 'pdf' }"
            src="icons/download.svg"
            :svg-attributes="{
              stroke: 'currentColor',
              title: 'File Size: ' + printFileSize(basketItem.fileSize),
            }"
            @click.stop="downloadBasketItem(basketItem)"
          />

          <base-svg
            class="text-primary-red cursor-pointer hover:text-custom-gray-1 mr-3 w-4 h-4"
            :class="{ 'mr-3': basketItemType === 'pdf' }"
            src="icons/cross.svg"
            @click.stop="confirmDeleteBasketItem(basketItem)"
          />

          <base-svg
            v-if="basketItemType === 'pdf'"
            :title="'Open in New Tab (File Size: ' + printFileSize(basketItem.fileSize)"
            class="text-primary-red cursor-pointer hover:text-custom-gray-1 mr-3 w-4 h-4"
            src="icons/expand.svg"
            @click.stop="openPdf()"
          />
        </div>
      </template>
    </style-guide-asset-card>

    <base-modal
      v-model="showDeleteBasketModal"
      modal-title="Confirm Delete Basket Item"
      @hidden="resetDeleteBasketModal()"
    >
      <template #modalBody>
        <div class="px-4 py-3">
          <div class="font-sm text-center mb-2">
            Are you sure to delete <strong>{{ basketToBeDeleted.name }}</strong> ?
          </div>
          <div class="text-center mt-2">
            <base-button
              class="mr-3"
              variant="btn-primary"
              text="Yes"
              :is-loading="isBasketRemoveLoading"
              @click="deleteBasketItem()"
            />
            <base-button
              variant="btn-link"
              text="No"
              @click="resetDeleteBasketModal()"
            />
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue';
import useBasket from '@/hooks/basket.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { useStore } from 'vuex';
import { SUCCESS, WARNING, ERROR, ASSET_DOWNLOADED_SUCCESSFULLY, ASSET_REMOVE_FROM_BASKET_SUCCESSFULLY, ASSET_REMOVE_FROM_BASKET_FAILED, ASSET_DOWNLOAD_FAILED } from '@/constants/alerts.js';
import { printFileSize } from '@/helpers/util';

// import useDownloadAsset from '@/hooks/downloadAsset.js';

export default {
    name: 'GalleryAssetCard',

    components: {
        StyleGuideAssetCard: defineAsyncComponent(() => import('@/components/StyleGuideAssetCard.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    props: {
        basketItem: {
            type: Object,
            required: true
        }
    },

    setup (props) {
        const { notificationsStack } = useToastNotifications();
        const store = useStore();

        const basketItemType = computed(() => (props?.basketItem?.name?.split('.').pop()||'').toLowerCase());
        const canDownload = computed(() => props?.basketItem?.canDownload);

        const isBasketRemoveLoading = ref(false);

        const basket = computed(() => store.getters['basket/getBasketList']);
        const fetchBasket = async () => {
            try {
                await store.dispatch('basket/fetchBasketList');
            } catch (err) {
                console.error(err);
            }
        };

        // delete basket item logic
        const { removeFromBasket } = useBasket();
        const deleteBasketItem = async () => {
            try {
                isBasketRemoveLoading.value = true;
                await removeFromBasket(basketToBeDeleted.value.originalFileId);
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: ASSET_REMOVE_FROM_BASKET_FAILED
                });
            } finally {
                await fetchBasket();
                resetDeleteBasketModal();
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: ASSET_REMOVE_FROM_BASKET_SUCCESSFULLY
                });
                isBasketRemoveLoading.value = false;
            }
        };

        // download basket item logic
        const isOperationLoading = ref(false);
        const downloadBasketItem = async (asset) => {
            if (isOperationLoading.value) return;
            try {
                isOperationLoading.value = true;

                let getPresignedURL
                {
                  let url = props.basketItem.asgardMasterBaseUrl
                  if (!url) throw `Master Download URL is not available`

                  const masterData = {
                    fileId: props.basketItem.styleGuideFileId,
                    objectId: props.basketItem.styleGuideAssetId,
                    filename: props.basketItem.name,
                    licenseeIds: (store.getters['auth/getUserLicensees'] || []).map(l => l.licenseeId),
                    downloadType: 'Asset Gallery'
                  }
                  url += `?masterData=${encodeURIComponent(window.btoa(JSON.stringify(masterData)))}`

                  if ( (props.basketItem.wmFlag||'').toLowerCase().startsWith('y') ) url = `${url}&skipWatermark=true`

                  getPresignedURL = await store.dispatch('upload/getDownloadAttachmentURLViaUrl', { params: { url } })

                }

                if (!getPresignedURL || !getPresignedURL?.presignedURL) throw new Error('Download URL is not available.')
                const windowOpened = window.open(getPresignedURL?.presignedURL, '_blank');
                if (!windowOpened) {
                    notificationsStack.value.push({
                        type: ERROR,
                        message: 'A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.'
                    });
                } else {
                    notificationsStack.value.push({
                        type: SUCCESS,
                        message: ASSET_DOWNLOADED_SUCCESSFULLY
                    });
                }
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: `${ASSET_DOWNLOAD_FAILED}. ${err}`
                });
            } finally {
                isOperationLoading.value = false;
            }
        };

        // pdf logic
        const openPdf = async () => {
            try {
                isOperationLoading.value = true;

                let url = props.basketItem.renditions.transformations.pdf
                if ( (props.basketItem.wmFlag||'').toLowerCase().startsWith('y') ) url = `${url}&skipWatermark=true`

                const resp = await store.dispatch('upload/getDownloadAttachmentURLViaUrl', {
                    params: {
                        url
                    }
                })
                if (!resp?.presignedUrl) throw new Error('Download URL is not available.')
                const windowOpened = window.open(resp?.presignedUrl, '_blank');
                if (!windowOpened) {
                    notificationsStack.value.push({
                        type: ERROR,
                        message: 'A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.'
                    });
                } else {
                    notificationsStack.value.push({
                        type: SUCCESS,
                        message: ASSET_DOWNLOADED_SUCCESSFULLY
                    });
                }

            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: `${ASSET_DOWNLOAD_FAILED}. ${err}`
                });
            } finally {
                isOperationLoading.value = false;
            }
        };

        // confirm delete search modal logic
        const confirmDeleteBasketItem = (basket) => {
            basketToBeDeleted.value = basket;
            setDeleteBasketModalVisibility(true);
        };

        const showDeleteBasketModal = ref(false);
        const setDeleteBasketModalVisibility = (visibility) => {
            showDeleteBasketModal.value = visibility;
        };
        const basketToBeDeleted = ref(null);
        const resetDeleteBasketModal = () => {
            basketToBeDeleted.value = null;
            setDeleteBasketModalVisibility(false);
        };

        return {
            downloadBasketItem,
            deleteBasketItem,
            openPdf,
            basketItemType,
            isOperationLoading,
            isBasketRemoveLoading,
            printFileSize,
            resetDeleteBasketModal,
            showDeleteBasketModal,
            confirmDeleteBasketItem,
            basketToBeDeleted,
            canDownload
        };
    }
};
</script>
